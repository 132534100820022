import React from "react";
import { t } from "react-multi-lang";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Formik, Form, Field, ErrorMessage } from "formik";
import configuration from "react-global-configuration";
import { Button } from "react-bootstrap";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { useDispatch } from "react-redux";
import { usernameValidationDefault } from "../../store/actions/UserAction";

const years = range(1950, getYear(new Date()) + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SignUpStep1 = (props) => {

  const dispatch = useDispatch();

  const handleNext = async (e) => {
    const validationErrors = await props.validateForm();
    // Optionally do something with validationErrors here
    if(!validationErrors.email && !validationErrors.date_of_birth && !validationErrors.name && !validationErrors.password && !validationErrors.username && props.valdiationU?.isValid){
      props.setFieldError('selectedCategories', '');
      props.jumpToStep(1);
      console.log("submited next")
    }else{
      console.log("Errors:", validationErrors);
    }
  };

  return (
    <div className="all-step">
      <div className="all-step-input-goup">
        <div class="form-group">
          <Field
            type="text"
            name="name"
            placeholder={t("name")}
            className="form-control"
            autoComplete="off"
          />
          <ErrorMessage
            component={"div"}
            name="name"
            className="text-danger text-right"
          />
        </div>

        <div className="form-group">
          <Field
            type="text"
            name="username"
            placeholder={t("username")}
            className="form-control"
            validate={props.handleUsernameValidation}
            autoComplete="off"
          />
          <ErrorMessage
            component={"div"}
            name="username"
            className="text-danger text-right"
          />
          {props.valdiationU?.isInValid &&
                                props?.isvalidUserName ? (
                                  <div class="text-danger text-right">
                                    {props.valdiationU.errorMessage ??
                                      t("username_already_taken")}
                                  </div>
                                ) : (
                                  ""
                                )}
          {props.valdiationU?.isValid && props?.isvalidUserName ? (
                                  <div class="text-success text-right">
                                    {t("looks_good")}
                                  </div>
                                ) : (
                                  ""
                                )}
        </div>

        <div className="form-group">
          <Field
            type="email"
            name="email"
            placeholder={t("email_address")}
            className="form-control mb-3"
            autoComplete="off"
          />
          <ErrorMessage
            component={"div"}
            name="email"
            className="text-danger text-right"
          />
        </div>

        <div className="form-group">
          <div class="input-group">
            <Field
              type={
                props.loginPasswordVisible ? "text" : "password"
              }
              name="password"
              placeholder={t("password")}
              className="form-control mb-3"
              autoComplete="off"
            />
            <div class="input-group-append">
              <button
                onClick={() =>
                  props.setLoginPasswordVisible(
                    !props.loginPasswordVisible
                  )
                }
                class="btn password-eye"
                type="button"
              >
                {props.loginPasswordVisible ? (
                                        <i className="fas fa-eye-slash align-self-center"></i>
                                      ) : (
                                        <i className="fas fa-eye align-self-center"></i>
                                      )}
              </button>
            </div>
          </div>
          <ErrorMessage
            component={"div"}
            name="password"
            className="text-danger text-right"
          />
        </div>

        <div className="form-group w-100">
          <div
            className="input-group date-picker-regsiter "
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                alignSelf: "baseline",
                fontSize: "18px",
                color: "rgb(151 157 163)",
                fontWeight: "400",
                padding: "0.5em",
                margin: "0px"
              }}
            >
              Date Of Birth
            </p>
            <Field
             style={{width: "100%"}}
            type="text"
            name="date_of_birth"
            onChange={(e)=>{

              props.setValues({
                ...props.values,
                date_of_birth: e.target.value,
              });

              function isUserAbove18(selectedDate) {
                // Parse the date string "mm/dd/yyyy"
                const [month, day, year] = selectedDate.split('/').map(Number);
                const parsedDate = new Date(year, month - 1, day); // month is zero-based
              
                const currentDate = new Date();
                let userAge = currentDate.getFullYear() - parsedDate.getFullYear();
              
                // Adjust age if the birthday hasn't occurred yet this year
                if (
                  parsedDate.getMonth() > currentDate.getMonth() ||
                  (parsedDate.getMonth() === currentDate.getMonth() && parsedDate.getDate() > currentDate.getDate())
                ) {
                  userAge--;
                }
              
                props.setIsAdult(userAge >= 18);
                return userAge >= 18;
              }
              
              // Example usage
              isUserAbove18(e.target.value);
              

            }}
            placeholder={t("mm/dd/yyyy")}
            className="form-control mb-3"
            autoComplete="off"
          />
            {/* <DatePicker
              showIcon
              name="date_of_birth"
              selected={props.values.date_of_birth}
              onChange={(date) => {
                props.setValues({
                  ...props.values,
                  date_of_birth: date,
                });
                function isUserAbove18(selectedDate) {
                  selectedDate = new Date(selectedDate);
                  var currentDate = new Date();
                  var userAge =
                    currentDate.getFullYear() - selectedDate.getFullYear();
                  if (
                    selectedDate.getMonth() > currentDate.getMonth() ||
                    (selectedDate.getMonth() === currentDate.getMonth() &&
                      selectedDate.getDate() > currentDate.getDate())
                  ) {
                    userAge--;
                  }
                  props.setIsAdult(userAge >= 18);
                  return userAge >= 18;
                }
                isUserAbove18(date);
              }}
              placeholderText="mm-dd-yyyy"
              maxDate={new Date()}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                </div>
              )}
            /> */}
          </div>
          <ErrorMessage
            component={"div"}
            name="date_of_birth"
            className="text-danger text-right"
          />
        </div>
      </div>

      {/* {configuration.get(
                                "configData.is_referral_enabled"
                              ) == 1 ? (
                                <>
                                  <div class="form-group">
                                    <Field
                                      type="text"
                                      name="referral_code"
                                      placeholder={t("referral_code_optional")}
                                      value={referralCode}
                                      className="form-control mb-3"
                                      onChange={(e) =>
                                        setReferralCode(e.target.value)
                                      }
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="referral_code"
                                      className="text-danger text-right"
                                    />
                                    <div className="check-referral-link">
                                      <a
                                        className="text-primary"
                                        href="#"
                                        onClick={checkReferralCode}
                                      >
                                        {t("check_referral_code_valid")}
                                      </a>
                                    </div>
                                  </div>
                                </>
                              ) : null} */}
      <div className="register_step_next_prv_btn_wrapper">
        {/* <button onClick={handleNext}>Test</button> */}
        <Button
          type="submit"
          className="register_stpe_next_btn"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>

      <div className="round">
        <p className="terms text-center">
          {t("signing_up_confirmation")} <br></br>
          <Link to={`/page/terms`} target="_blank">
            {t("terms_of_service")}
          </Link>{" "}
          {t("and")}{" "}
          <Link to={`/page/privacy`} target="_blank">
            {t("privacy_policy")}
          </Link>
          .
        </p>
      </div>

      {/* <Button
                                type="submit"
                                className="btn gradient-btn gradientcolor"
                                disabled={
                                  props.signup.buttonDisable && !isSignUpOpen
                                }
                              >
                                {props.signup.loadingButtonContent !== null &&
                                !isSignUpOpen
                                  ? props.signup.loadingButtonContent
                                  : "Sign Up"}
                              </Button> */}

      <div className="signup_already_account_container">
        <p id="two">{t("already_have_an_account")}</p>
        <p>
          <Link
            className="signup"
            href="#"
            id="signin"
            onClick={(event) => {
              event.preventDefault();
              props.setShow("login");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              props.setValues({
                name: "",
                username: "",
                email: "",
                password: "",
                date_of_birth: "",
                selectedCategories: [],
                creatorCheck : false,
                matureChecked : false,
              });
              props.setInitalStep(0);
              dispatch(usernameValidationDefault());
            }}
          >
            {" "}
            {t("login_for")} {configuration.get("configData.site_name")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpStep1;
