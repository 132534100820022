import React, { Component } from "react";
import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsSuccess,
  userLoginSuccess,
  userLoginFailure,
  userRegisterSuccess,
  userRegisterFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateUserDetailsFailure,
  registerVerifyFailure,
  registerVerifyResendFailure,
  registerVerifySuccess,
  registerVerifyResendSuccess,
  notificationStatusUpdateSuccess,
  notificationStatusUpdateFailure,
  fetchPaymentsSuccess,
  fetchPaymentsFailure,
  saveBlockUserSuccess,
  saveBlockUserFailure,
  fetchBlockUsersSuccess,
  fetchBlockUsersFailure,
  resetPasswordFailure,
  resetPasswordSuccess,
  usernameValidationSuccess,
  usernameValidationFailure,
  referralValidationSuccess,
  referralValidationFailure,
  fetchContentCreatorDashboardSuccess,
  fetchContentCreatorDashboardFailure,
  twoStepAuthUpdateSuccess,
  twoStepAuthUpdateFAilure,
  twoStepAuthenticationLoginSuccess,
  twoStepAuthenticationLoginFailure,
  twoStepAuthenticationCodeResendSuccess,
  twoStepAuthenticationCodeResendFailure,
  saveFeatureStorySuccess,
  saveFeatureStoryFailure,
  deleteFeatureStorySuccess,
  deleteFeatureStoryFailure,
  fetchUserDetailsStart,
  saveRestrictUserSuccess,
  saveRestrictUserFailure,
  fetchRestrictedUsersSuccess,
  fetchRestrictedUsersFailure,
  saveReportUserSuccess,
  saveReportUserFailure,
  saveTagUntagUserSuccess,
  saveTagUntagUserFailure,
  getUserCountryFailure,
  getUserCountrySuccess,
  removeUserFromListSuccess,
  removeUserFromListFailure,
  addMultiUserTagFailure,
  addMultiUserTagSuccess,
  addCategoriesFailure,
  addCategoriesSuccess,
} from "../actions/UserAction";

import api from "../../Environment";
import {
  FETCH_USER_DETAILS_START,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_SUBSCRIPTION_DETAILS_START,
  LOGIN_START,
  REGISTER_START,
  FORGOT_PASSWORD_START,
  DELETE_ACCOUNT_START,
  REGISTER_VERIFY_START,
  REGISTER_VERIFY_RESEND_START,
  NOTIFICATION_STATUS_UPDATE_START,
  FETCH_PAYMENTS_START,
  FETCH_BLOCK_USERS_START,
  SAVE_BLOCK_USER_START,
  USER_VERIFY_BADGE_STATUS_START,
  RESET_PASSWORD_START,
  USERNAME_VALIDATION_START,
  REFERRAL_VALIDATION_START,
  FETCH_CONTENT_CREATOR_DASHBOARD_START,
  TWO_STEP_AUTH_UPDATE_START,
  TWO_STEP_AUTHENTICATION_LOGIN_START,
  TWO_STEP_AUTHENTICATION_CODE_RESEND_START,
  SAVE_FEATURE_STORY_START,
  DELETE_FEATURE_STORY_START,
  SAVE_RESTRICT_USER_START,
  FETCH_RESTRICTED_USERS_START,
  SAVE_REPORT_USER_START,
  SAVE_TAG_UNTAG_USER_START,
  GET_USER_COUNTRY_START,
  REMOVE_USER_FROM_LIST_START,
  ADD_MULTI_USER_TAG_START,
  Add_CATEGORIES_ID_START,
} from "../actions/ActionConstant";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import { checkLogoutStatus } from "../actions/ErrorAction";
import { id } from "date-fns/locale";
import { addUserListSuccessD, getSingleSearchListUserSuccess } from "../actions/CustomListAction";
import { string } from "yup";
import { getAllFriendSuccess } from "../actions/FriendRequestAction";
import {
  fetchActiveFollowersSuccess,
  fetchActiveFollowingSuccess,
} from "../actions/FollowAction";
import { fetchSubscribingSuccess } from "../actions/SubscribingAction";
import { fetchSubscribersSuccess } from "../actions/SubscriberAction";
import { fetchFormerSubscriberSuccess } from "../actions/FormerSubscriberAction";
import { fetchUnsubscribedSuccess } from "../actions/UnsubscribedAction";
import { fetchFavSuccess } from "../actions/FavAction";
import { searchListUserSuccess } from "../actions/HomeAction";

function* getUserDetailsAPI({ data }) {
  try {
    const response = yield api.postMethod(
      "profile",
      data?.is_approve_tc ? { is_approve_tc: data.is_approve_tc ? 1 : 0 } : null
    );

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem(
        "total_followers",
        response.data.data.total_followers
      );
      localStorage.setItem(
        "total_followings",
        response.data.data.total_followings
      );
      localStorage.setItem(
        "is_subscription_enabled",
        response.data.data.is_subscription_enabled
      );
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      localStorage.setItem(
        "is_content_creator",
        response.data.data.is_content_creator
      );
      localStorage.setItem(
        "default_payment_method",
        response.data.data.default_payment_method
      );
      localStorage.setItem(
        "is_two_step_auth_enabled",
        response.data.data.is_two_step_auth_enabled
      );

      if (data?.directCreator && data?.directCreator !== undefined) {
        if (
          response.data?.data?.is_approve_tc === 1 &&
          response.data?.data?.content_creator_step !== 2
        ) {
          const notificationMessage = getSuccessNotificationMessage(
            "Please Wait for Admin approve the document"
          );
          yield put(createNotification(notificationMessage));
        }
      }
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      yield put(checkLogoutStatus(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addCategoriesToProfile({data}) {

  try {
  
    const response = yield api.postMethod(
      "update_profile_category",
      {category_id: data.category_id}
    );

    if (response.data.success) {
      yield put(addCategoriesSuccess(response.data.data))

      const userDetails = yield select(state => state.users.profile.data)

      if (response.data.data.is_category_add === 1) {

        const updatedUsersDetails = {
          ...userDetails,
          is_category_add: response.data.data.is_category_add
        }

        yield put(fetchUserDetailsSuccess({data: updatedUsersDetails}))

        data.jumpToStep(3)
      }
    } else {
      yield put(addCategoriesFailure(response.data.error));
      // yield put(checkLogoutStatus(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addCategoriesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const userData = yield select((state) => state.users.profileInputData.data);
    const response = yield api.postMethod("update_profile", action.data);
    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      localStorage.setItem(
        "is_content_creator",
        response.data.data.is_content_creator
      );
      localStorage.setItem(
        "default_payment_method",
        response.data.data.default_payment_method
      );
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
      // window.location.assign("/profile");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(updateUserDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* userLoginAPI() {
  try {
    const userData = yield select((state) => state.users.loginInputData.data);
    const response = yield api.postMethod("login", userData);
    yield put(userLoginSuccess(response.data));
    if (response.data.success) {
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem(
        "device_unique_id",
        response.data.data.device_unique_id
      );
      if (response.data.code == 1001)
        window.location.assign("/register/verify");
      else {
        if (response.data.code == 240) {
          const notificationMessage = getSuccessNotificationMessage(
            response.data.message
          );
          yield put(createNotification(notificationMessage));
          window.location.assign("/verification");
          localStorage.setItem("emailId", response.data.data.email);
        } else {
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("user_cover", response.data.data.cover);
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("username", response.data.data.username);
          localStorage.setItem("socket", true);
          localStorage.setItem(
            "user_unique_id",
            response.data.data.user_unique_id
          );
          localStorage.setItem(
            "is_document_verified",
            response.data.data.is_document_verified
          );
          localStorage.setItem(
            "is_verified_badge",
            response.data.data.is_verified_badge
              ? response.data.data.is_verified_badge
              : 0
          );
          localStorage.setItem(
            "is_content_creator",
            response.data.data.is_content_creator
          );
          localStorage.setItem(
            "default_payment_method",
            response.data.data.default_payment_method
          );
          localStorage.setItem(
            "is_two_step_auth_enabled",
            response.data.data.is_two_step_auth_enabled
          );
          localStorage.setItem("emailId", response.data.data.email);
          const notificationMessage = getSuccessNotificationMessage(
            response.data.message
          );
          yield put(createNotification(notificationMessage));
          if (userData.isReload) {
            window.location.reload();
          } else {
            if (
              response.data?.data?.is_certified_content_creator === 1 &&
              response.data?.data?.muture_content === 1 &&
              response.data?.data?.want_to_creator === 1
            ) {
              window.location.assign("/become-a-certified-creator");
            } else {
              window.location.assign("/home");
            }
          }
        }
      }
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* userRegisterAPI() {
  try {
    const userData = yield select(
      (state) => state.users.registerInputData.data
    );

    const response = yield api.postMethod("register", userData);
    yield put(userRegisterSuccess(response.data));

    if (response.data.success) {
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem(
        "device_unique_id",
        response.data.data.device_unique_id
      );
      if (response.data.code == 1001) {
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/register/verify");
      } else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("user_cover", response.data.data.cover);
        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("socket", true);
        localStorage.setItem( 
          "user_unique_id",
          response.data.data.user_unique_id
        );
        localStorage.setItem(
          "is_document_verified",
          response.data.data.is_document_verified
        );
        localStorage.setItem(
          "is_verified_badge",
          response.data.data.is_verified_badge
            ? response.data.data.is_verified_badge
            : 0
        );
        localStorage.setItem(
          "is_two_step_auth_enabled",
          response.data.data.is_two_step_auth_enabled
        );
        localStorage.setItem(
          "is_content_creator",
          response.data.data.is_content_creator
        );
        localStorage.setItem(
          "default_payment_method",
          response.data.data.default_payment_method
        );
        localStorage.setItem("emailId", response.data.data.email);
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        if (response.data.data.is_welcome_steps == 1) {
          window.location.assign("/upload-profile-picture");
        } else {
          if (userData.isReload) {
            window.location.reload();
          } else {
            if (userData?.isMutureContent) {
              window.location.assign("/become-a-certified-creator")
              return
            }
            window.location.assign("/home");
          }
        }
      }
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(userRegisterFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* forgotPasswordAPI() {
  try {
    const userData = yield select(
      (state) => state.users.forgotPasswordInputData.data
    );

    if (
      !userData.email ||
      userData.email == undefined ||
      userData.email == null
    ) {
      const notificationMessage = getErrorNotificationMessage(
        "Please enter the email address"
      );
      yield put(createNotification(notificationMessage));
      yield put(forgotPasswordFailure());
    } else {
      const response = yield api.postMethod("forgot_password", userData);
      yield put(forgotPasswordSuccess(response.data));
      if (response.data.success) {
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        yield put(createNotification(notificationMessage));
        window.location.assign("/");
      } else {
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(createNotification(notificationMessage));
      }
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteAccountAPI() {
  try {
    const userData = yield select(
      (state) => state.users.deleteAccount.inputData
    );
    const response = yield api.postMethod("delete_account", userData);
    yield put(deleteAccountSuccess(response.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteAccountFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* registerVerify() {
  try {
    const inputData = yield select(
      (state) => state.users.registerVerify.inputData
    );

    const response = yield api.postMethod("verify_email", inputData);

    if (response.data.success) {
      yield put(registerVerifySuccess(response.data));
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("accessToken", response.data.data.token);
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("username", response.data.data.first_name);
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      localStorage.setItem("socket", true);
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/home");
    } else {
      yield put(registerVerifyFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(registerVerifyFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* registerVerifyResend() {
  try {
    const response = yield api.postMethod("regenerate_email_verification_code");

    if (response.data.success) {
      yield put(registerVerifyResendSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(registerVerifyResendFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(registerVerifyResendFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* notificationStatusUpdateAPI() {
  try {
    const userData = yield select(
      (state) => state.users.notificationUpdate.inputData
    );
    const response = yield api.postMethod(
      "notifications_status_update",
      userData
    );
    if (response.data.success) {
      yield put(notificationStatusUpdateSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(notificationStatusUpdateFailure(response.data.error));
    }
  } catch (error) {
    yield put(notificationStatusUpdateFailure(error));
    const notificationMessage = getErrorNotificationMessage(
      error.response.data.error
    );
    yield put(createNotification(notificationMessage));
  }
}

function* verificationBadgeStatusUpdateAPI() {
  try {
    const userData = yield select(
      (state) => state.users.verifyBadgeUpdate.inputData
    );
    const response = yield api.postMethod("verified_badge_status", userData);
    if (response.data.success) {
      yield put(notificationStatusUpdateSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(notificationStatusUpdateFailure(response.data.error));
    }
  } catch (error) {
    yield put(notificationStatusUpdateFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getPaymentsAPI() {
  try {
    const response = yield api.postMethod("payments_index");

    if (response.data.success) {
      yield put(fetchPaymentsSuccess(response.data));
    } else {
      yield put(fetchPaymentsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPaymentsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBlockUsersAPI({ data }) {
  try {
    const listData = yield select(
      (state) => state.customList.addUser.allListData
    );

    if (data !== undefined && data.setIsLoadingList !== undefined) {
      data.setIsLoadingList(true);
    }
    const response = yield api.postMethod("block_users");
    if (response.data.success) {
      if (data !== undefined && data.setIsLoadingList !== undefined) {
        data.setIsLoadingList(false);
      }

      const updatedBlockedUser = response.data.data.block_users.map(
        (item) => {
          return {
            ...item,
            blockeduser: {
              ...item.blockeduser,
              isSelected: false,
            },
          };
        }
      );

      yield put(
        fetchBlockUsersSuccess({
          block_users: updatedBlockedUser,
          total: response?.data?.data?.total,
        })
      );
      // yield put(fetchBlockUsersSuccess(response.data.data));

      if (data !== undefined && data.allListData !== undefined) {
        const updatedData = listData.map((parentEle) => {
          if (parentEle.list_name === data.typeList) {
            const updatedData = response?.data?.data?.block_users?.map(
              (ele) => {
                return {
                  ...ele,
                  customListFlag: "BlockedList",
                  isSelected: parentEle.isSelected ? true : false,
                };
              }
            );

            return {
              ...parentEle,
              data: updatedData,
              total: response?.data?.data?.total,
            };
          } else {
            return parentEle;
          }
        });
        yield put(addUserListSuccessD(updatedData));
      }
    } else {
      if (data !== undefined && data.setIsLoadingList !== undefined) {
        data.setIsLoadingList(false);
      }

      yield put(fetchBlockUsersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    if (data !== undefined && data.setIsLoadingList !== undefined) {
      data.setIsLoadingList(false);
    }
    yield put(fetchBlockUsersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchRestrictedUsersAPI({ data }) {
  try {
    if (data !== undefined && data.setIsLoadingList !== undefined) {
      data.setIsLoadingList(true);
    }
    const response = yield api.postMethod("restrict_users");
    if (response.data.success) {
      if (data !== undefined && data.setIsLoadingList !== undefined) {
        data.setIsLoadingList(false);
      }

      const updatedRestrictedUser = response.data.data.restrict_users.map(
        (item) => {
          return {
            ...item,
            restricteduser: {
              ...item.restricteduser,
              isSelected: false,
            },
          };
        }
      );

      yield put(
        fetchRestrictedUsersSuccess({
          restrict_users: updatedRestrictedUser,
          total: response?.data?.data?.total,
        })
      );
      // yield put(fetchRestrictedUsersSuccess(response.data.data));

      if (data !== undefined && data.allListData !== undefined) {
        const updatedData = data.allListData.map((ele) => {
          if (ele.list_name === data.typeList) {
            const updatedData = response?.data?.data?.restrict_users?.map(
              (ele) => {
                return {
                  ...ele,
                  customListFlag: "RestrictedList",
                };
              }
            );

            return {
              ...ele,
              data: updatedData,
              total: response?.data?.data?.total,
            };
          } else {
            return ele;
          }
        });
        yield put(addUserListSuccessD(updatedData));
      }
    } else {
      if (data !== undefined && data.setIsLoadingList !== undefined) {
        data.setIsLoadingList(false);
      }

      yield put(fetchRestrictedUsersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    if (data !== undefined && data.setIsLoadingList !== undefined) {
      data.setIsLoadingList(false);
    }
    yield put(fetchRestrictedUsersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveBlockUserAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.saveBlockUser.inputData
    );
    const response = yield api.postMethod("block_users_save", inputData);
    if (response.data.success) {
      yield put(saveBlockUserSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.reload();
      localStorage.setItem(
        "total_followers",
        response.data.data.total_followers
      );
      localStorage.setItem(
        "total_followings",
        response.data.data.total_followings
      );

      if (inputData.is_other_profile == 1) {
        window.location.reload();
      }
    } else {
      yield put(saveBlockUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveBlockUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* removeUserFromList({payload}) {
  try {
    const inputData = payload?.type === 'custom_list' ? {users_ids: payload?.users_ids, type: payload?.type, custom_list_id: payload?.custom_list_id} : {users_ids: payload?.users_ids, type: payload?.type}
    const response = yield api.postMethod("remove_list_multi_users", inputData);

    if (response.data.success) {
      yield put(removeUserFromListSuccess(response.data.data))

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      if (payload?.type === 'following' || payload?.type === "friends" || payload?.type === "custom_list") {

        const updatedData = payload?.userData?.filter((elem) => !payload.users_ids?.split(',').includes(elem.user_id.toString()));

        payload.setUserData(updatedData)
        payload.setIsSelected(false)
        payload.setCheckedUserId([]);
        return payload.handleCloseRemoveUserModal();
      }

      if (payload?.type === 'unsubscribe') {
        const updatedData = payload?.userData?.filter((elem) => !payload.users_ids?.split(',').includes(elem.to_user_id.toString()));

        payload.setUserData(updatedData)
        payload.setIsSelected(false)
        payload.setCheckedUserId([]);
        return payload.handleCloseRemoveUserModal();
      }

      if (payload?.type === 'resticted') {
        const updatedData = payload?.userData?.filter((elem) => !payload.users_ids?.split(',').includes(elem.restricteduser.user_id.toString()));

        payload.setUserData(updatedData)
        payload.setIsSelected(false)
        payload.setCheckedUserId([]);
        return payload.handleCloseRemoveUserModal();
      }

      if (payload?.type === 'blocked') {
        const updatedData = payload?.userData?.filter((elem) => !payload.users_ids?.split(',').includes(elem.blockeduser.user_id.toString()));

        payload.setUserData(updatedData)
        payload.setIsSelected(false)
        payload.setCheckedUserId([]);
        return payload.handleCloseRemoveUserModal();
      }

      if (payload?.type === 'favourite') {
        const updatedData = payload?.userData?.filter((elem) => !payload.users_ids?.split(',').includes(elem.fav_user.user_id.toString()));

        payload.setUserData(updatedData)
        payload.setIsSelected(false)
        payload.setCheckedUserId([]);
        return payload.handleCloseRemoveUserModal();
      }

    } else {
      yield put(removeUserFromListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));

      payload.setIsSelected(false)
      payload.setCheckedUserId([]);
      return payload.handleCloseRemoveUserModal();
    }
  } catch (error) {
    yield put(removeUserFromListFailure(error))
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));

    payload.setIsSelected(false)
    payload.setCheckedUserId([]);
    return payload.handleCloseRemoveUserModal();
  }
}

function* saveReportUserAPI({ data }) {
  try {
    // const inputData = yield select(
    //   (state) => state.users.reportUsers.inputData
    // );
    const inputData = {
      user_id: data?.user_id,
      reason: data?.reason,
      report_file: data?.reportFile,
    };
    const response = yield api.postMethod("report_user_save", inputData);
    if (response.data.success) {
      yield put(saveReportUserSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      return data?.closeReportModeModal();
    } else {
      yield put(saveReportUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      return data?.closeReportModeModal();
    }
  } catch (error) {
    yield put(saveReportUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
    return data?.closeReportModeModal();
  }
}

function* saveTagUntagUserAPI({ data }) {
  try {
    const inputData = data?.untag
      ? { user_id: data?.user_id, 
        tag_colour_code: data?.tag_colour_code, 
        tag_title: data?.tag_title}
      : {
          user_id: data?.user_id,
          tag_title: data?.tag_title,
          tag_colour_code: data?.tag_colour_code,
        };

    const response = yield api.postMethod("user_tag_save", inputData);

    if (response.data.success) {
      yield put(saveTagUntagUserSuccess(response.data.data));

      if (data?.friendType) {
        const friendData = yield select(
          (state) => state.friendRequest.allActiveFriends.data
        );

        const updatedFriendData = friendData.friends.map((item) => {
          if (
            String(item?.user?.user_id) === response.data.data.tag_to ||
            item?.user?.user_id === data?.user_id
          ) {
            return {
              ...item,
              tag_info: data?.untag
                ? {}
                : {
                    tag_title: response.data.data.tag_title,
                    colour: response.data.data.tag_colour_code,
                  },
            };
          } else {
            return item;
          }
        });

        yield put(
          getAllFriendSuccess({
            friends: updatedFriendData,
            total: friendData.total,
          })
        );
      }

      if (data?.followingType) {
        const followingData = yield select(
          (state) => state.follow.activeFollowing.data
        );

        const updatedFollowingData = followingData.followers.map((item) => {
          if (
            String(item?.otherUser?.user_id) === response.data.data.tag_to ||
            item?.otherUser?.user_id === data?.user_id
          ) {
            return {
              ...item,
              otherUser: {
                ...item.otherUser,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              },
            };
          } else {
            return item;
          }
        });

        yield put(
          fetchActiveFollowingSuccess({
            followers: updatedFollowingData,
            total: followingData.total,
          })
        );
      }

      if (data?.followersType) {
        const followersData = yield select(
          (state) => state.follow.activeFollowers.data
        );

        const updatedFollowersData = followersData.followers.map((item) => {
          if (
            String(item?.otherUser?.user_id) === response.data.data.tag_to ||
            item?.otherUser?.user_id === data?.user_id
          ) {
            return {
              ...item,
              otherUser: {
                ...item.otherUser,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              },
            };
          } else {
            return item;
          }
        });

        yield put(
          fetchActiveFollowersSuccess({
            followers: updatedFollowersData,
            total: followersData.total,
          })
        );
      }

      if (data?.subscribingType) {
        const subscribingData = yield select(
          (state) => state.subscriber.subscribingUser.data
        );

        const updatedSubscribingData = subscribingData.subscribing.map(
          (item) => {
            if (
              String(item?.to_user?.user_id) === response.data.data.tag_to ||
              item?.to_user?.user_id === data?.user_id
            ) {
              return {
                ...item,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              };
            } else {
              return item;
            }
          }
        );

        yield put(
          fetchSubscribingSuccess({
            subscribing: updatedSubscribingData,
            total: subscribingData.total,
          })
        );
      }

      if (data?.subscribersType) {
        const subscribersData = yield select(
          (state) => state.subscriber.subscribers.data
        );

        const updatedSubscribersData = subscribersData.subscriber.map(
          (item) => {
            if (
              String(item?.from_user?.user_id) === response.data.data.tag_to ||
              item?.from_user?.user_id === data?.user_id
            ) {
              return {
                ...item,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              };
            } else {
              return item;
            }
          }
        );

        yield put(
          fetchSubscribersSuccess({
            subscriber: updatedSubscribersData,
            total: subscribersData.total,
          })
        );
      }

      if (data?.formerSubscriberType) {
        const formerSubscribersData = yield select(
          (state) => state.subscriber.formerSubscriber.data
        );

        const updatedFormerSubscribersData =
          formerSubscribersData.subscriber.map((item) => {
            if (
              String(item?.from_user?.user_id) === response.data.data.tag_to ||
              item?.from_user?.user_id === data?.user_id
            ) {
              return {
                ...item,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              };
            } else {
              return item;
            }
          });

        yield put(
          fetchFormerSubscriberSuccess({
            subscriber: updatedFormerSubscribersData,
            total: formerSubscribersData.total,
          })
        );
      }

      if (data?.unsubscribedType) {
        const unsubscibedData = yield select(
          (state) => state.subscriber.unsubscribed.data
        );

        const updatedUnsubscibedData = unsubscibedData.unsubscribed.map(
          (item) => {
            if (
              String(item?.to_user?.user_id) === response.data.data.tag_to ||
              item?.to_user?.user_id === data?.user_id
            ) {
              return {
                ...item,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              };
            } else {
              return item;
            }
          }
        );

        yield put(
          fetchUnsubscribedSuccess({
            unsubscribed: updatedUnsubscibedData,
            total: unsubscibedData.total,
          })
        );
      }

      if (data?.favouriteType) {
        const favouritesData = yield select((state) => state.fav.fav.data);

        const updatedFavouritesData = favouritesData.favs.map((item) => {
          if (
            String(item?.fav_user?.user_id) === response.data.data.tag_to ||
            item?.fav_user?.user_id === data?.user_id
          ) {
            return {
              ...item,
              fav_user: {
                ...item.fav_user,
                tag_info: data?.untag
                  ? {}
                  : {
                      tag_title: response.data.data.tag_title,
                      colour: response.data.data.tag_colour_code,
                    },
              },
            };
          } else {
            return item;
          }
        });

        yield put(
          fetchFavSuccess({ favs: updatedFavouritesData, tagUntagFav: true })
        );
      }

      if (data?.blockType) {
        const blockData = yield select((state) => state.users.blockUsers.data);

        const updatedBlockData = blockData.block_users.map((item) => {
          if (
            String(item?.blockeduser?.user_id) === response.data.data.tag_to ||
            item?.blockeduser?.user_id === data?.user_id
          ) {
            return {
              ...item,
              tag_info: data?.untag 
                ? {} 
                : {
                tag_title: response.data.data.tag_title,
                colour: response.data.data.tag_colour_code
              }
            }
          } else {
            return item;
          }
        });

        yield put(fetchBlockUsersSuccess({block_users: updatedBlockData, total: blockData.total}))
      }

      if (data?.restrictedType) {
        const restrictUserData = yield select((state) => state.users.restrictUsers.data)

        const updatedRestrictUserData = restrictUserData.restrict_users.map(item => {
          if ((String(item?.restricteduser?.user_id) === response.data.data.tag_to) || (item?.restricteduser?.user_id === data?.user_id)) {
            return {
              ...item,
              tag_info: data?.untag ? {} : {
                tag_title: response.data.data.tag_title,
                colour: response.data.data.tag_colour_code
              }
            }
          } else {
            return item
          }
      });

        yield put(
          fetchRestrictedUsersSuccess({
            restrict_users: updatedRestrictUserData,
            total: restrictUserData.total,
          })
        );
      }

      if (data?.singleListUserType) {
        const userData = yield select((state) => state.customList.userSearchList.data)

        console.log(userData)
        const updatedUserData = userData.map(item => {
          if ((String(item?.user_id) === response.data.data.tag_to) || (item?.user_id === data?.user_id))  {
            return {
              ...item,
              tag_info: data?.untag ? {} : {
                tag_title: response.data.data.tag_title,
                colour: response.data.data.tag_colour_code
              }
            }
          } else {
            return item
          }
        })

        yield put(
          searchListUserSuccess(updatedUserData)
        )
      }

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      if (data?.tagModel) {
        return data?.closeTagUntagModel();
      }
    } else {
      yield put(saveTagUntagUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));

      if (data?.tagModel) {
        return data?.closeTagUntagModel();
      }
    }
  } catch (error) {
    yield put(saveTagUntagUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));

    if (data?.tagModel) {
      return data?.closeTagUntagModel();
    }
  }
}

function* saveMultiUserTag() {
  try {
    const inputData = yield select(state => state.users.tagMultiUser.inputData)

    const response = yield api.postMethod("multi_user_tag_save", {
      user_id: inputData?.user_id,
      tag_title: inputData?.tag_title,
      tag_colour_code: inputData?.tag_colour_code,
    });

    if (response.data.success) {
      debugger;
      yield put(addMultiUserTagSuccess(response.data.data))

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));


      if (inputData?.listType === 'following' || inputData?.listType === 'followers' || inputData?.listType === 'friends') {
      
        const usersData = inputData?.listType === 'following'
          ? 
          yield select(
            (state) => state.follow.activeFollowing.data
          )
          :
            inputData?.listType === 'followers'
            ?
            yield select(
              (state) => state.follow.activeFollowers.data
            ) 
            :
            inputData?.listType === 'friends'
              ?
              yield select(
                (state) => state.friendRequest.allActiveFriends.data
              )
              : []

        const updatedData = inputData?.listType === 'friends'
        ?
          usersData?.friends?.map((elem) => {
            if (inputData?.user_id?.includes(elem.user_id)) {
              return {
                ...elem,
                tag_info: {
                  tag_title: response.data.data.tag_title,
                  colour: response.data.data.tag_colour_code,
                },
                user: {
                  ...elem.user,
                  isSelected: false,
                },
              };
            } else {
              return {
                ...elem,
                isSelected: false,
              };
            }
          })       
        :
          usersData?.followers.map((elem) => {
          if (inputData?.user_id?.includes(elem.otherUser.user_id)) {
            return {
              ...elem,
              otherUser: {
                ...elem.otherUser,
                tag_info: {
                  tag_title: response.data.data.tag_title,
                  colour: response.data.data.tag_colour_code,
                },
                isSelected: false,
              },
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        if (inputData?.listType === 'following') {
          yield put(
            fetchActiveFollowingSuccess({
              followers: updatedData,
              total: usersData.total,
            })
          );
        }

        if (inputData?.listType === 'followers'){
          yield put(
            fetchActiveFollowersSuccess({
              followers: updatedData,
              total: usersData.total,
            })
          );
        }

        if (inputData?.listType === 'friends') {
          yield put(
            getAllFriendSuccess({
              friends: updatedData,
              total: usersData.total,
            })
          );
        }
        // inputData.setUserData(updatedData);
        inputData.setIsSelected(false);
        inputData.setCheckedUserId([]);
        inputData.handleCloseRemoveUserTagModal();
      }

      if (inputData?.listType === 'subscribing' || inputData?.listType === 'unsubscribed' || inputData?.listType === 'subscribers' || inputData?.listType === 'formerSubscribers') {

        const usersData = 
          inputData?.listType === 'subscribers'
            ?
            yield select(
              (state) => state.subscriber.subscribers.data
            )
            : 
            inputData?.listType === 'formerSubscribers'
              ?
              yield select(
                (state) => state.subscriber.formerSubscriber.data
              )
              : 
              inputData?.listType === 'subscribing'
                ?
                yield select(
                  (state) => state.subscriber.subscribingUser.data
                )
                :
                inputData?.listType === 'unsubscribed'
                  ?
                  yield select(
                    (state) => state.subscriber.unsubscribed.data
                  ): []


        const updatedData = 
        (inputData?.listType === 'subscribers' || inputData?.listType === 'formerSubscribers')
        ?
          usersData?.subscriber.map((elem) => {
            if (inputData?.user_id?.includes(elem.from_user?.user_id)) {
              return {
                ...elem,
                tag_info: {
                  tag_title: response.data.data.tag_title,
                  colour: response.data.data.tag_colour_code,
                },
                isSelected: false,
              };
            } else {
              return {
                ...elem,
                isSelected: false,
              };
            }
          })
        : 
        inputData?.listType === 'unsubscribed'
          ?
          usersData?.unsubscribed?.map((elem) => {
            if (inputData?.user_id?.includes(elem.to_user?.user_id)) {
              return {
                ...elem,
                tag_info: {
                  tag_title: response.data.data.tag_title,
                  colour: response.data.data.tag_colour_code,
                },
                isSelected: false,
              };
            } else {
              return {
                ...elem,
                isSelected: false,
              };
            }
          })
          :
          usersData?.subscribing?.map((elem) => {
            if (inputData?.user_id?.includes(elem.to_user?.user_id)) {
              return {
                ...elem,
                tag_info: {
                  tag_title: response.data.data.tag_title,
                  colour: response.data.data.tag_colour_code,
                },
                isSelected: false,
              };
            } else {
              return {
                ...elem,
                isSelected: false,
              };
            }
          });

        if (inputData?.listType === 'subscribers') {
          yield put(
            fetchSubscribersSuccess({
              subscriber: updatedData,
              total: usersData.total,
            })
          );
        }

        if (inputData?.listType === 'formerSubscribers') {
          yield put(
            fetchFormerSubscriberSuccess({
              subscriber: updatedData,
              total: usersData.total,
            })
          );
        }

        if (inputData?.listType === 'subscribing') {
          yield put(
            fetchSubscribingSuccess({
              subscribing: updatedData,
              total: usersData.total,
            })
          );
        }

        if (inputData?.listType === 'unsubscribed') {
          yield put(
            fetchUnsubscribedSuccess({
              unsubscribed: updatedData,
              total: usersData.total,
            })
          );
        }
        // inputData.setUserData(updatedData);
        inputData.setIsSelected(false);
        inputData.setCheckedUserId([]);
        inputData.handleCloseRemoveUserTagModal();
      }

      if (inputData?.listType === 'favorites') {
        const favouritesData = yield select((state) => state.fav.fav.data);

        const updatedData = favouritesData.favs.map((elem) => {
          if (inputData.user_id.includes(elem.fav_user?.user_id)) {
            return {
              ...elem,
              fav_user: {
                ...elem.fav_user,
                isSelected: false,
                tag_info: {
                  tag_title: response.data.data.tag_title,
                  colour: response.data.data.tag_colour_code,
                },
              },
            };
          } else {
            return {
              ...elem,
              fav_user: {
                ...elem.fav_user,
                isSelected: false,
              },
            };
          }
        });

        yield put(
          fetchFavSuccess({ favs: updatedData, tagUntagFav: true })
        );
        // inputData.setUserData(updatedData);
        inputData.setIsSelected(false);
        inputData.setCheckedUserId([]);
        inputData.handleCloseRemoveUserTagModal();
      }

      if (inputData?.listType === 'restricted') {
        const restrictUserData = yield select((state) => state.users.restrictUsers.data)

        const updatedData = restrictUserData.restrict_users.map((elem) => {
          if (inputData.user_id.includes(elem.restricteduser?.user_id)) {
            return {
              ...elem,
              restricteduser: {
                ...elem.restricteduser,
                isSelected: false,
              },
              tag_info: {
                tag_title: response.data.data.tag_title,
                colour: response.data.data.tag_colour_code,
              },
            };
          } else {
            return {
              ...elem,
              restricteduser: {
                ...elem.restricteduser,
                isSelected: false,
              },
            };
          }
        });

        yield put(
          fetchRestrictedUsersSuccess({
            restrict_users: updatedData,
            total: restrictUserData.total,
          })
        );

        // inputData.setUserData(updatedData);
        inputData.setIsSelected(false);
        inputData.setCheckedUserId([]);
        inputData.handleCloseRemoveUserTagModal();
      }

      if (inputData?.listType === 'blocked') {
        const blockData = yield select((state) => state.users.blockUsers.data);

        const updatedData = blockData.block_users.map((elem) => {
          if (inputData.user_id.includes(elem.blockeduser?.user_id)) {
            return {
              ...elem,
              blockeduser: {
                ...elem.blockeduser,
                isSelected: false,
              },
              tag_info: {
                tag_title: response.data.data.tag_title,
                colour: response.data.data.tag_colour_code,
              },
            };
          } else {
            return {
              ...elem,
              blockeduser: {
                ...elem.blockeduser,
                isSelected: false,
              },
            };
          }
        });

        yield put(fetchBlockUsersSuccess({block_users: updatedData, total: blockData.total}))

        // inputData.setUserData(updatedData);

        inputData.setIsSelected(false);
        inputData.setCheckedUserId([]);
        inputData.handleCloseRemoveUserTagModal();
      }

    } else {
      yield put(addMultiUserTagFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addMultiUserTagFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveRestrictUserAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.saveRestrictUser.inputData
    );
    const response = yield api.postMethod("restricted_users_save", inputData);
    if (response.data.success) {
      yield put(saveRestrictUserSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      window.location.reload();
      localStorage.setItem(
        "total_followers",
        response.data.data.total_followers
      );
      localStorage.setItem(
        "total_followings",
        response.data.data.total_followings
      );

      if (inputData.is_other_profile == 1) {
        window.location.reload();
      }
    } else {
      yield put(saveRestrictUserFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveRestrictUserFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveFeatureStoryAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.saveFeatureStory.inputData
    );
    const response = yield api.postMethod("feature_story_save", inputData);
    if (response.data.success) {
      yield put(saveFeatureStorySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchUserDetailsStart());
    } else {
      yield put(saveFeatureStoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveFeatureStoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteFeatureStoryAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.deleteFeatureStory.inputData
    );
    const response = yield api.postMethod("feature_story_delete", inputData);
    if (response.data.success) {
      yield put(deleteFeatureStorySuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchUserDetailsStart());
    } else {
      yield put(deleteFeatureStoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteFeatureStoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* resetPasswordAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.resetPasswordInputData.inputData
    );
    const response = yield api.postMethod("reset_password", inputData);
    yield put(resetPasswordSuccess(response.data));
    if (response.data.success) {
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("socket", true);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      yield put(createNotification(notificationMessage));
      window.location.assign("/home");
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(resetPasswordFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* usernameValidationAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.validationInputData.data
    );
    const response = yield api.postMethod("username_validation", inputData);
    yield put(usernameValidationSuccess(response.data));
    if (response.data.success) {
    } else {
      yield put(usernameValidationFailure(response));
      // const notificationMessage = getErrorNotificationMessage(
      //   response.data.error
      // );
      // yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(usernameValidationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* referralValidationAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.referralInputData.data
    );
    const response = yield api.postMethod("referral_code_validate", inputData);
    yield put(referralValidationSuccess(response.data));
    if (response.data.success) {
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(referralValidationFailure(response));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(referralValidationFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getContentCreatorDashboardAPI() {
  try {
    const response = yield api.postMethod("content_creators_dashboard");

    if (response.data.success) {
      yield put(fetchContentCreatorDashboardSuccess(response.data));
    } else {
      yield put(fetchContentCreatorDashboardFailure(response.data.error));
      yield put(checkLogoutStatus(response.data));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchContentCreatorDashboardFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* updateUserSubscriptionDetailsAPI() {
  try {
    const userData = yield select(
      (state) => state.users.profileSubscriptionInputData.data
    );
    const response = yield api.postMethod("update_profile", userData);
    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      localStorage.setItem(
        "is_verified_badge",
        response.data.data.is_verified_badge
          ? response.data.data.is_verified_badge
          : 0
      );
      localStorage.setItem(
        "is_content_creator",
        response.data.data.is_content_creator
      );
      localStorage.setItem(
        "default_payment_method",
        response.data.data.default_payment_method
      );
      const notificationMessage = getSuccessNotificationMessage(
        "User Subscription Details Updated"
      );
      yield put(createNotification(notificationMessage));
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(updateUserDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* twoStepAuthenticationUpdateAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_update", action.data);

    if (response.data.success) {
      yield put(twoStepAuthUpdateSuccess(response.data));
      localStorage.setItem(
        "is_two_step_auth_enabled",
        response.data.data.is_two_step_auth_enabled
      );
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(twoStepAuthUpdateFAilure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(twoStepAuthUpdateFAilure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* twoStepAuthenticationLoginAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_login", action.data);
    yield put(twoStepAuthenticationLoginSuccess(response.data));
    if (response.data.success) {
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
      if (response.data.code == 1001)
        window.location.assign("/register/verify");
      else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("user_cover", response.data.data.cover);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem("socket", true);
        localStorage.setItem(
          "user_unique_id",
          response.data.data.user_unique_id
        );
        localStorage.setItem(
          "is_document_verified",
          response.data.data.is_document_verified
        );
        localStorage.setItem(
          "is_verified_badge",
          response.data.data.is_verified_badge
            ? response.data.data.is_verified_badge
            : 0
        );
        localStorage.setItem(
          "is_content_creator",
          response.data.data.is_content_creator
        );
        localStorage.setItem(
          "default_payment_method",
          response.data.data.default_payment_method
        );
        localStorage.setItem(
          "is_two_step_auth_enabled",
          response.data.data.is_two_step_auth_enabled
        );
        const notificationMessage = getSuccessNotificationMessage(
          response.data.message
        );
        localStorage.setItem("emailId", response.data.data.email);
        yield put(createNotification(notificationMessage));
        window.location.assign("/home");
      }
    } else {
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
      yield put(twoStepAuthenticationLoginFailure(response.data.error));
    }
  } catch (error) {
    yield put(twoStepAuthenticationLoginFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* twoStepAuthenticationCodeResendAPI(action) {
  try {
    const response = yield api.postMethod(
      "two_step_auth_resend_code",
      action.data
    );

    if (response.data.success) {
      yield put(twoStepAuthenticationCodeResendSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(twoStepAuthenticationCodeResendFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(twoStepAuthenticationCodeResendFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getUserCountryInfoApi(action) {
  try {
    const response = yield fetch(
      "https://api.ipgeolocation.io/ipgeo?apiKey=014d43740e0643dc90d1e90f43a69ae5&fields=geo"
    );
    const data = yield response.json();

    if (response.ok) {
      const censorshipCountryRes = yield api.postMethod(
        "get_sensor_country_status",
        { country_code: data?.country_code2 }
      );
      console.log(
        "🚀 ~ function*getUserCountryInfoApi ~ censorshipCountryRes:",
        censorshipCountryRes
      );
      if (censorshipCountryRes.data.success) {
        localStorage.setItem(
          "countryDetail",
          JSON.stringify({
            country_name: data?.country_name,
            country_code2: data?.country_code2,
            city: data?.city,
            state_prov: data?.state_prov,
            zipcode: data?.zipcode,
            isCensorship:
              censorshipCountryRes.data.data === "enable" ? true : false,
          })
        );
        yield put(
          getUserCountrySuccess({
            country_name: data?.country_name,
            country_code2: data?.country_code2,
            city: data?.city,
            state_prov: data?.state_prov,
            zipcode: data?.zipcode,
            isCensorship:
              censorshipCountryRes.data.data === "enable" ? true : false,
          })
        );
      }
    } else {
      yield put(getUserCountryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getUserCountryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(Add_CATEGORIES_ID_START, addCategoriesToProfile),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(
      UPDATE_USER_SUBSCRIPTION_DETAILS_START,
      updateUserSubscriptionDetailsAPI
    ),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(REGISTER_VERIFY_START, registerVerify),
    yield takeLatest(REGISTER_VERIFY_RESEND_START, registerVerifyResend),
    yield takeLatest(
      NOTIFICATION_STATUS_UPDATE_START,
      notificationStatusUpdateAPI
    ),
    yield takeLatest(FETCH_PAYMENTS_START, getPaymentsAPI),
    yield takeLatest(FETCH_BLOCK_USERS_START, fetchBlockUsersAPI),
    yield takeLatest(FETCH_RESTRICTED_USERS_START, fetchRestrictedUsersAPI),
    yield takeLatest(SAVE_BLOCK_USER_START, saveBlockUserAPI),
    yield takeLatest(REMOVE_USER_FROM_LIST_START, removeUserFromList),
    yield takeLatest(SAVE_RESTRICT_USER_START, saveRestrictUserAPI),
    yield takeLatest(SAVE_REPORT_USER_START, saveReportUserAPI),
    yield takeLatest(SAVE_TAG_UNTAG_USER_START, saveTagUntagUserAPI),
    yield takeLatest(ADD_MULTI_USER_TAG_START, saveMultiUserTag),
    yield takeLatest(
      USER_VERIFY_BADGE_STATUS_START,
      verificationBadgeStatusUpdateAPI
    ),
    yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(USERNAME_VALIDATION_START, usernameValidationAPI),
    yield takeLatest(REFERRAL_VALIDATION_START, referralValidationAPI),
    yield takeLatest(
      FETCH_CONTENT_CREATOR_DASHBOARD_START,
      getContentCreatorDashboardAPI
    ),
    yield takeLatest(
      TWO_STEP_AUTH_UPDATE_START,
      twoStepAuthenticationUpdateAPI
    ),
    yield takeLatest(
      TWO_STEP_AUTHENTICATION_LOGIN_START,
      twoStepAuthenticationLoginAPI
    ),
    yield takeLatest(
      TWO_STEP_AUTHENTICATION_CODE_RESEND_START,
      twoStepAuthenticationCodeResendAPI
    ),
    yield takeLatest(SAVE_FEATURE_STORY_START, saveFeatureStoryAPI),
    yield takeLatest(DELETE_FEATURE_STORY_START, deleteFeatureStoryAPI),
    yield takeLatest(GET_USER_COUNTRY_START, getUserCountryInfoApi),
  ]);
}
