import {
  SAVE_POST_START,
  SAVE_POST_SUCCESS,
  SAVE_POST_FAILURE,
  FETCH_POSTS_START,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_SINGLE_POST_START,
  FETCH_SINGLE_POST_SUCCESS,
  FETCH_SINGLE_POST_FAILURE,
  DELETE_POST_START,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  CHANGE_POST_STATUS_START,
  CHANGE_POST_STATUS_SUCCESS,
  CHANGE_POST_STATUS_FAILURE,
  POST_FILE_UPLOAD_START,
  POST_FILE_UPLOAD_SUCCESS,
  POST_FILE_UPLOAD_FAILURE,
  PPV_PAYMENT_STRIPE_START,
  PPV_PAYMENT_STRIPE_SUCCESS,
  PPV_PAYMENT_STRIPE_FAILURE,
  PPV_PAYMENT_WALLET_START,
  PPV_PAYMENT_WALLET_SUCCESS,
  PPV_PAYMENT_WALLET_FAILURE,
  SAVE_REPORT_POST_START,
  SAVE_REPORT_POST_SUCCESS,
  SAVE_REPORT_POST_FAILURE,
  FETCH_REPORT_POSTS_START,
  FETCH_REPORT_POSTS_SUCCESS,
  FETCH_REPORT_POSTS_FAILURE,
  PPV_PAYMENT_PAYPAL_START,
  PPV_PAYMENT_PAYPAL_SUCCESS,
  PPV_PAYMENT_PAYPAL_FAILURE,
  PPV_PAYMENT_CCBILL_START,
  PPV_PAYMENT_CCBILL_SUCCESS,
  PPV_PAYMENT_CCBILL_FAILURE,
  FETCH_EXPLORE_START,
  FETCH_EXPLORE_SUCCESS,
  FETCH_EXPLORE_FAILURE,
  POST_FILE_REMOVE_START,
  POST_FILE_REMOVE_SUCCESS,
  POST_FILE_REMOVE_FAILURE,
  PPV_PAYMENT_COINPAYMENT_START,
  PPV_PAYMENT_COINPAYMENT_SUCCESS,
  PPV_PAYMENT_COINPAYMENT_FAILURE,
  FETCH_POST_CATEGORIES_START,
  FETCH_POST_CATEGORIES_SUCCESS,
  FETCH_POST_CATEGORIES_FAILURE,
  FETCH_REPORT_REASON_START,
  FETCH_REPORT_REASON_SUCCESS,
  FETCH_REPORT_REASON_FAILURE,
  POST_FILTER_UPLOAD_START,
  POST_FILE_THUMBNAIL_UPLOAD_START,
  POST_FILE_REMOVE_THUBNAIL_START,
  SAVE_POST_DRAFT_START,
  SAVE_POST_DRAFT_SUCCESS,
  SAVE_POST_DRAFT_FAILURE,
  GET_POST_DRAFT_START,
  GET_POST_DRAFT_SUCCESS,
  GET_POST_DRAFT_FAILURE,
  SAVE_SINGLE_POST_DRAFT_START,
  SAVE_SINGLE_POST_DRAFT_SUCCESS,
  SAVE_SINGLE_POST_DRAFT_FAILURE,
  DELETE_DRAFT_POST_START,
  DELETE_DRAFT_POST_SUCCESS,
  DELETE_DRAFT_POST_FAILURE,
  SET_POST_DRAFT_SUCCESS,
  SAVE_SCHADUAL_POST_START,
  SAVE_SCHADUAL_POST_SUCCESS,
  SAVE_SCHADUAL_POST_FAILURE,
  GET_SCHEDUAL_POST_DRAFT_START,
  GET_SCHEDUAL_POST_DRAFT_SUCCESS,
  GET_SCHEDUAL_POST_DRAFT_FAILURE,
  DELETE_SCHEDUAL_DRAFT_POST_START,
  DELETE_SCHEDUAL_DRAFT_POST_SUCCESS,
  DELETE_SCHEDUAL_DRAFT_POST_FAILURE,
  SET_SCHEDUAL_POST_DRAFT_SUCCESS,
  EDIT_SCHADUAL_POST_START,
  EDIT_SCHADUAL_POST_SUCCESS,
  EDIT_SCHADUAL_POST_FAILURE,
  SAVE_SINGLE_SCHEDUAL_POST_DRAFT_START,
  SAVE_SINGLE_SCHEDUAL_POST_DRAFT_SUCCESS,
  SAVE_SINGLE_SCHEDUAL_POST_DRAFT_FAILURE,
  FETCH_SEARCH_PAGE_CONTENT_START,
  FETCH_SEARCH_PAGE_CONTENT_SUCCESS,
  FETCH_SEARCH_PAGE_CONTENT_FAILURE,
  SEARCH_PAGE_DATA_DEFAULT,
  SEARCH_VALUE_CHANGE,
  GET_FORWARD_POST_FOLLOWERS_USER_LIST_START,
  GET_FORWARD_POST_FOLLOWERS_USER_LIST_SUCCESS,
  GET_FORWARD_POST_FOLLOWERS_USER_LIST_FAILURE,
  GET_FORWARD_POST_FOLLOWERS_USERS_SEARCH_LIST_START,
  GET_FORWARD_POST_FOLLOWERS_USERS_SEARCH_LIST_SUCCESS,
  GET_FORWARD_POST_FOLLOWERS_USERS_SEARCH_LIST_FAILURE,
  SENT_FORWARD_POST_START,
  SENT_FORWARD_POST_SUCCESS,
  SENT_FORWARD_POST_FAILURE
} from "./ActionConstant";

export function savePostStart(data) {
  return {
    type: SAVE_POST_START,
    data,
  };
}

export function savePostSuccess(data) {
  return {
    type: SAVE_POST_SUCCESS,
    data,
  };
}

export function savePostFailure(error) {
  return {
    type: SAVE_POST_FAILURE,
    error,
  };
}


export function saveDraftPostStart(data) {
  return {
    type: SAVE_POST_DRAFT_START,
    data,
  };
}

export function saveDraftPostSuccess(data) {
  return {
    type: SAVE_POST_DRAFT_SUCCESS,
    data,
  };
}

export function saveDraftPostFailure(error) {
  return {
    type: SAVE_POST_DRAFT_FAILURE,
    error,
  };
}
export function saveSchadualTimeStart(data) {
  return {
    type: SAVE_SCHADUAL_POST_START,
    data,
  };
}

export function saveSchadualTimeSuccess(data) {
  return {
    type: SAVE_SCHADUAL_POST_SUCCESS,
    data,
  };
}

export function saveSchadualTimeFailure(error) {
  return {
    type: SAVE_SCHADUAL_POST_FAILURE,
    error,
  };
}

export function editSchadualTimeStart(data) {
  return {
    type: EDIT_SCHADUAL_POST_START,
    data,
  };
}

export function editSchadualTimeSuccess(data) {
  return {
    type: EDIT_SCHADUAL_POST_SUCCESS,
    data,
  };
}

export function editSchadualTimeFailure(error) {
  return {
    type: EDIT_SCHADUAL_POST_FAILURE,
    error,
  };
}



export function fetchPostsStart(data) {
  return {
    type: FETCH_POSTS_START,
    data,
  };
}

export function fetchPostsSuccess(data) {
  return {
    type: FETCH_POSTS_SUCCESS,
    data,
  };
}

export function fetchPostsFailure(error) {
  return {
    type: FETCH_POSTS_FAILURE,
    error,
  };
}

export function fetchDraftPostsStart(data) {
  return {
    type: GET_POST_DRAFT_START,
    data,
  };
}

export function fetchDraftPostsSuccess(data) {
  return {
    type: GET_POST_DRAFT_SUCCESS,
    data,
  };
}

export function setDraftPostsSuccess(data) {
  return {
    type: SET_POST_DRAFT_SUCCESS,
    data,
  };
}

export function setSchedualDraftPostsSuccess(data) {
  return {
    type: SET_SCHEDUAL_POST_DRAFT_SUCCESS,
    data,
  };
}

export function fetchDraftPostsFailure(error) {
  return {
    type: GET_POST_DRAFT_FAILURE,
    error,
  };
}

export function saveSingleDraftPostsStart(data) {
  return {
    type: SAVE_SINGLE_POST_DRAFT_START,
    data,
  };
}

export function saveSingleDraftPostsSuccess(data) {
  return {
    type: SAVE_SINGLE_POST_DRAFT_SUCCESS,
    data,
  };
}

export function saveSingleDraftPostsFailure(error) {
  return {
    type: SAVE_SINGLE_POST_DRAFT_FAILURE,
    error,
  };
}

export function saveSingleSchedualDraftPostsStart(data) {
  return {
    type: SAVE_SINGLE_SCHEDUAL_POST_DRAFT_START,
    data,
  };
}

export function saveSingleSchedualDraftPostsSuccess(data) {
  return {
    type: SAVE_SINGLE_SCHEDUAL_POST_DRAFT_SUCCESS,
    data,
  };
}

export function saveSingleSchedualDraftPostsFailure(error) {
  return {
    type: SAVE_SINGLE_SCHEDUAL_POST_DRAFT_FAILURE,
    error,
  };
}

export function fetchSchedualDraftPostsStart(data) {
  return {
    type: GET_SCHEDUAL_POST_DRAFT_START,
    data,
  };
}

export function fetchSchedualDraftPostsSuccess(data) {
  return {
    type: GET_SCHEDUAL_POST_DRAFT_SUCCESS,
    data,
  };
}

export function fetchSchedualDraftPostsFailure(error) {
  return {
    type: GET_SCHEDUAL_POST_DRAFT_FAILURE,
    error,
  };
}



export function fetchSinglePostStart(data) {
  return {
    type: FETCH_SINGLE_POST_START,
    data,
  };
}

export function fetchSinglePostSuccess(data) {
  return {
    type: FETCH_SINGLE_POST_SUCCESS,
    data,
  };
}

export function fetchSinglePostFailure(error) {
  return {
    type: FETCH_SINGLE_POST_FAILURE,
    error,
  };
}

export function deletePostStart(data) {
  return {
    type: DELETE_POST_START,
    data,
  };
}

export function deletePostSuccess(data) {
  return {
    type: DELETE_POST_SUCCESS,
    data,
  };
}

export function deletePostFailure(error) {
  return {
    type: DELETE_POST_FAILURE,
    error,
  };
}
export function deleteDraftPostStart(data) {
  return {
    type: DELETE_DRAFT_POST_START,
    data,
  };
}


export function deleteDraftPostSuccess(data) {
  return {
    type: DELETE_DRAFT_POST_SUCCESS,
    data,
  };
}

export function deleteDraftPostFailure(error) {
  return {
    type: DELETE_DRAFT_POST_FAILURE,
    error,
  };
}


export function deleteSchedualDraftPostStart(data) {
  return {
    type: DELETE_SCHEDUAL_DRAFT_POST_START,
    data,
  };
}

export function deleteSchedualDraftPostSuccess(data) {
  return {
    type: DELETE_SCHEDUAL_DRAFT_POST_SUCCESS,
    data,
  };
}

export function deleteSchedualDraftPostFailure(error) {
  return {
    type: DELETE_SCHEDUAL_DRAFT_POST_FAILURE,
    error,
  };
}


export function changePostStatusStart(data) {
  return {
    type: CHANGE_POST_STATUS_START,
    data,
  };
}

export function changePostStatusSuccess(data) {
  return {
    type: CHANGE_POST_STATUS_SUCCESS,
    data,
  };
}

export function changePostStatusFailure(error) {
  return {
    type: CHANGE_POST_STATUS_FAILURE,
    error,
  };
}

export function postFileUploadStart(data) {
  return {
    type: POST_FILE_UPLOAD_START,
    data,
  };
}

export function postFileUploadSuccess(data) {
  return {
    type: POST_FILE_UPLOAD_SUCCESS,
    data,
  };
}

export function postFileUploadFailure(error) {
  return {
    type: POST_FILE_UPLOAD_FAILURE,
    error,
  };
}

export function postFileThumbnailUploadStart(data) {
  return {
    type: POST_FILE_THUMBNAIL_UPLOAD_START,
    data,
  };
}

export function postFilterUploadStart(data) {
  return {
    type: POST_FILTER_UPLOAD_START,
    data,
  };
}

export function PPVPaymentStripeStart(data) {
  return {
    type: PPV_PAYMENT_STRIPE_START,
    data,
  };
}

export function PPVPaymentStripeSuccess(data) {
  return {
    type: PPV_PAYMENT_STRIPE_SUCCESS,
    data,
  };
}

export function PPVPaymentStripeFailure(error) {
  return {
    type: PPV_PAYMENT_STRIPE_FAILURE,
    error,
  };
}

// Subscription Payment wallet actions.

export function PPVPaymentWalletStart(data) {
  return {
    type: PPV_PAYMENT_WALLET_START,
    data,
  };
}

export function PPVPaymentWalletSuccess(data) {
  return {
    type: PPV_PAYMENT_WALLET_SUCCESS,
    data,
  };
}

export function PPVPaymentWalletFailure(error) {
  return {
    type: PPV_PAYMENT_WALLET_FAILURE,
    error,
  };
}

export function saveReportPostStart(data) {
  return {
    type: SAVE_REPORT_POST_START,
    data,
  };
}

export function saveReportPostSuccess(data) {
  return {
    type: SAVE_REPORT_POST_SUCCESS,
    data,
  };
}

export function saveReportPostFailure(error) {
  return {
    type: SAVE_REPORT_POST_FAILURE,
    error,
  };
}

export function fetchReportPostsStart(data) {
  return {
    type: FETCH_REPORT_POSTS_START,
    data,
  };
}

export function fetchReportPostsSuccess(data) {
  return {
    type: FETCH_REPORT_POSTS_SUCCESS,
    data,
  };
}

export function fetchReportPostsFailure(error) {
  return {
    type: FETCH_REPORT_POSTS_FAILURE,
    error,
  };
}

export function PPVPaymentPaypalStart(data) {
  return {
    type: PPV_PAYMENT_PAYPAL_START,
    data,
  };
}

export function PPVPaymentPaypalSuccess(data) {
  return {
    type: PPV_PAYMENT_PAYPAL_SUCCESS,
    data,
  };
}

export function PPVPaymentPaypalFailure(error) {
  return {
    type: PPV_PAYMENT_PAYPAL_FAILURE,
    error,
  };
}

export function PPVPaymentCCBillStart(data) {
  return {
    type: PPV_PAYMENT_CCBILL_START,
    data,
  };
}

export function postFileRemoveStart(data) {
  return {
    type: POST_FILE_REMOVE_START,
    data,
  };
}
export function postFileRemoveThubnailStart(data) {
  return {
    type: POST_FILE_REMOVE_THUBNAIL_START,
    data,
  };
}

export function PPVPaymentCCBillSuccess(data) {
  return {
    type: PPV_PAYMENT_CCBILL_SUCCESS,
    data,
  };
}

export function postFileRemoveSuccess(data) {
  return {
    type: POST_FILE_REMOVE_SUCCESS,
    data,
  };
}

export function postFileRemoveFailure(error) {
  return {
    type: POST_FILE_REMOVE_FAILURE,
    error,
  };
}

export function PPVPaymentCCBillFailure(error) {
  return {
    type: PPV_PAYMENT_CCBILL_FAILURE,
    error,
  };
}

export function fetchExploreStart(data) {
  return {
    type: FETCH_EXPLORE_START,
    data,
  };
}

export function fetchExploreSuccess(data) {
  return {
    type: FETCH_EXPLORE_SUCCESS,
    data,
  };
}

export function fetchExploreFailure(error) {
  return {
    type: FETCH_EXPLORE_FAILURE,
    error,
  };
}

export function PPVPaymentCoinPaymentStart(data) {
  return {
    type: PPV_PAYMENT_COINPAYMENT_START,
    data,
  };
}
export function PPVPaymentCoinPaymentSuccess(data) {
  return {
    type: PPV_PAYMENT_COINPAYMENT_SUCCESS,
    data,
  };
}

export function PPVPaymentCoinPaymentFailure(error) {
  return {
    type: PPV_PAYMENT_COINPAYMENT_FAILURE,
    error,
  };
}
export function fetchPostCategoriesStart(data) {
  return {
    type: FETCH_POST_CATEGORIES_START,
    data,
  };
}

export function fetchPostCategoriesSuccess(data) {
  return {
    type: FETCH_POST_CATEGORIES_SUCCESS,
    data,
  };
}

export function fetchPostCategoriesFailure(error) {
  return {
    type: FETCH_POST_CATEGORIES_FAILURE,
    error,
  };
}

export function fetchReportReasonStart(data) {
  return {
    type: FETCH_REPORT_REASON_START,
    data,
  };
}

export function fetchReportReasonSuccess(data) {
  return {
    type: FETCH_REPORT_REASON_SUCCESS,
    data,
  };
}

export function fetchReportReasonFailure(error) {
  return {
    type: FETCH_REPORT_REASON_FAILURE,
    error,
  };
}

export function fetchSearchPageContentStart(data) {
  return {
    type: FETCH_SEARCH_PAGE_CONTENT_START,
    payload : data,
  };
}

export function fetchSearchPageContentSuccess(data) {
  return {
    type: FETCH_SEARCH_PAGE_CONTENT_SUCCESS,
    payload : data,
  };
}

export function fetchSearchPageContentFailure(error) {
  return {
    type: FETCH_SEARCH_PAGE_CONTENT_FAILURE,
    error,
  };
}


export function clearSearchPageData(data) {
  return {
    type: SEARCH_PAGE_DATA_DEFAULT,
    payload : data,
  };
}

export function searchValueChange(data) {
  return {
    type: SEARCH_VALUE_CHANGE,
    payload : data,
  };
}



export function fetchForwardPostFollowerUserListStart(data) {
  return {
    type: GET_FORWARD_POST_FOLLOWERS_USER_LIST_START,
    payload : data,
  };
}


export function fetchForwardPostFollowerUserListSuccess(data) {
  return {
    type: GET_FORWARD_POST_FOLLOWERS_USER_LIST_SUCCESS,
    payload : data,
  };
}

export function fetchForwardPostFollowerUserListFailure(error) {
  return {
    type: GET_FORWARD_POST_FOLLOWERS_USER_LIST_FAILURE,
    error : error,
  };
}


export function fetchForwardPostUsersSearchListStart(data) {
  return {
    type: GET_FORWARD_POST_FOLLOWERS_USERS_SEARCH_LIST_START,
    payload : data,
  };
}


export function fetchForwardPostUsersSearchListSuccess(data) {
  return {
    type: GET_FORWARD_POST_FOLLOWERS_USERS_SEARCH_LIST_SUCCESS,
    payload : data,
  };
}

export function fetchForwardPostUsersSearchListFailure(error) {
  return {
    type: GET_FORWARD_POST_FOLLOWERS_USERS_SEARCH_LIST_FAILURE,
    error : error,
  };
}


export function sentForwardPostStart(data) {
  return {
    type: SENT_FORWARD_POST_START,
    payload : data,
  };
}


export function sentForwardPostSuccess(data) {
  return {
    type: SENT_FORWARD_POST_SUCCESS,
    payload : data,
  };
}

export function sentForwardPostFailure(error) {
  return {
    type: SENT_FORWARD_POST_FAILURE,
    error : error,
  };
}
